@import "../../../styles/variables.module";

.wrapper {
  position: fixed;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;

  @include media("<=tablet") {
    display: none;
  }

  .inner {
    position: relative;
    z-index: $player-fullscreen-z-index + 1;
    padding: 2.4rem 3.2rem 2.4rem;
    background-color: $color-primary;
    box-shadow: 0 -4px 10px 0 rgba(33, 33, 33, 0.65);

    @include media("<=tablet") {
      padding: 1.6rem;
    }

    @include media(">desktop") {
      padding: 1.6rem 5rem;
    }

    .header {
      font-size: 2.4rem;
      color: $color-light;
      font-weight: 600;
      margin-bottom: 1.6rem;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .buttons {
      display: flex;

      button + button {
        margin-left: 1.6rem;
      }

      .button_cta {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem;
        padding: 0.8rem 1.6rem;
        background-color: $color-light;
        color: $color-primary;
        font-weight: 700;
        white-space: nowrap;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .button_close {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.4rem;
        padding: 0.8rem 1.6rem;
        background-color: #3756d3;
        color: #ffffff;
        font-weight: 700;
        border-radius: 8px;
      }
    }
    .category_info {
      display: flex;
      align-items: center;

      .cover {
        width: 7.2rem;
        height: 7.2rem;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 1.6rem;
        box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
      }

      .category_title {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: $color-light;
        font-size: 3.2rem;
        font-weight: 600;
      }
      .category_subtitle {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        color: $color-light;
        font-size: 2.4rem;
      }
    }
  }
}
