@import "../../../styles/variables.module";

$header-background-desktop: rgba($color-light, 0.05);
$mobile-menu-height: 37rem;

.navigation_mobile {
  position: fixed;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  z-index: $z-index-base + 1;
  pointer-events: none;

  @include media(">tablet") {
    display: none;
  }

  &_inner {
    height: 14rem;
    align-items: flex-end;
    display: flex;
    justify-content: space-around;
    padding: 0.8rem;
    padding-bottom: 2.4rem;
    background: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.013) 3.2%,
      hsla(0, 0%, 0%, 0.049) 6.1%,
      hsla(0, 0%, 0%, 0.104) 9%,
      hsla(0, 0%, 0%, 0.175) 11.9%,
      hsla(0, 0%, 0%, 0.259) 15%,
      hsla(0, 0%, 0%, 0.352) 18.6%,
      hsla(0, 0%, 0%, 0.45) 22.9%,
      hsla(0, 0%, 0%, 0.55) 27.9%,
      hsla(0, 0%, 0%, 0.648) 33.8%,
      hsla(0, 0%, 0%, 0.741) 41%,
      hsla(0, 0%, 0%, 0.825) 49.4%,
      hsla(0, 0%, 0%, 0.896) 59.4%,
      hsla(0, 0%, 0%, 0.951) 71%,
      hsla(0, 0%, 0%, 0.987) 84.5%,
      hsl(0, 0%, 0%) 100%
    );

    a {
      pointer-events: all;
    }
  }

  .navigation_item {
    display: grid;
    justify-items: center;
    &.active {
      .label {
        color: $color-light;
      }
      svg * {
        fill: $color-light;
      }
    }
    .label {
      font-size: 1.6rem;
      margin-top: 0.4rem;
      font-weight: 500;
      color: #929292;
    }

    svg {
      height: 2.4rem;
      width: 2.4rem;
      
      * {
        fill: #929292;
      }
    }
  }
}
body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .profile {
      &:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        border-radius: 100%;
        transform: scale(1.1);
      }
    }
    .menu_items {
      .menu_item:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        opacity: 1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
.profile {
  display: flex;
  justify-content: flex-end;
  transition: 0.5s all;
  margin-left: 2.4rem;
  
  @include media("<desktop720p") {
    margin-left: 1.6rem;
  }
  @include media("<tablet") {
    margin-left: 0;
  }

  &.mobile {
    @include media(">tablet") {
      display: none;
    }
  }
  .icon {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    width: 2.3rem;
    height: 2.3rem;

    &_wrapper {
      background-color: rgba($color-light, 0.05);
      width: 4.6rem;
      height: 4.6rem;
      position: relative;
      border-radius: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .search_mobile & {
      top: 1.1rem;
    }
  }
}
.demo_indicator {
  color: $color-light;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  white-space: nowrap;
}
.header {
  position: relative;

  @include media(">phoneXL") {
    background-color: $header-background-desktop;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      z-index: 1;
      backdrop-filter: blur(120px);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .inner_wrapper {
    padding: 1.6rem 3.2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: $player-fullscreen-z-index - 1;
    position: relative;
    height: 9.9rem;

    @include media("<=tablet") {
      padding: 1.6rem;
    }
    @include media(">desktop") {
      padding: 1.6rem 5rem;
    }
  }

  .logo_wrapper {
    flex: 0 0;

    .logo {
      height: 2.4rem;
      width: 12.4rem;
      transition: 0.5s all;
    }
    a {
      transition: 0.5s all;
      display: inline-block;
    }
  }

  .player_wrapper {
    flex: 1;

    @include media("<=tablet") {
      position: fixed;
      bottom: 9.2rem;
      bottom: calc(9.2rem + constant(safe-area-inset-bottom, 0));
      bottom: calc(9.2rem + env(safe-area-inset-bottom, 0));
      left: 0;
      right: 0;
      z-index: $player-minimized-z-index;
    }
  }

  .side {
    flex: 2;
    display: flex;
    justify-content: flex-end;

    @include media("<=tablet") {
      display: none;
    }
    .side_items {
      display: grid;
      gap: 1.6rem;
      grid-auto-flow: column;
      justify-content: space-between;
      margin-left: 1.6rem;
    }
    .menu_items {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      max-width: 90rem;

      .menu_item + .menu_item {
        
        @include media(">desktop1600") {
          margin-left: 2.4rem;
        }
      }

      .menu_item {
        display: flex;
        align-items: center;
        opacity: 0.4;
        justify-content: flex-end;
        transition: 0.5s all;
        margin: 0 0.8rem;
        border-radius: 16px;
        padding: 0.4rem 0.8rem;

        @include media("<desktop720p") {
          margin: 0 0.8rem;
        }

        &.active {
          opacity: 1;
        }

        span {
          color: $color-light;
          font-size: 2.4rem;
          text-transform: uppercase;
          letter-spacing: 0.12rem;
          font-weight: $font-weight-bold;
          margin: 0 0.8rem;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          white-space: nowrap;

          @include media("<desktop720p") {
            display: none;
          }
        }

        &_icon {
          height: 2.4rem;
          width: 2.4rem;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

          &_wrapper {
            display: flex;

            @include media("<desktop720p") {
              width: 4.6rem;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .menu_toggle {
    color: #fff;
    border-radius: 4px;
    width: 5rem;
    height: 4.6rem;
    margin-left: 1rem;
    padding: 1rem;
    flex-direction: column;
    justify-content: space-between;
    display: none;

    @include media("<tablet") {
      display: flex;
    }

    .bar {
      background: #fff;
      height: 0.4rem;
      border-radius: 0.2rem;
    }
  }

  .mobile_menu_wrapper {
    display: none;
    transition: 0.5s all;
    left: 0;
    right: 0;

    @include media("<tablet") {
      display: block;
      z-index: $player-fullscreen-z-index - 1;
      width: 100%;
      position: absolute;
      top: 100%;
      overflow: hidden;
      height: 0;
    }

    &.mobile_active {
      height: $mobile-menu-height;
      background-color: #002e53;

      .menu_items {
        z-index: $z-index-base;
      }
    }

    .menu_items {
      transition: 0.5s all;

      @include media("<tablet") {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        padding: 2.4rem 0;
        background-color: #0d3255;
      }

      .menu_item {
        padding: 0.8rem 0;

        span {
          @include media("<tablet") {
            display: inline-block;
          }
        }
      }
    }
  }
}
.search_mobile {
  @include media(">tablet") {
    display: none;
  }
}
