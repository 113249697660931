@import "../../../styles/variables.module";

.footer {
  color: $color-light;
  padding: 6.4rem 1.6rem;
  margin: 0 auto;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/gradientUpsideDown.jpg");
  background-repeat: repeat-x;
  background-position: center top, center -80%;

  &.no_background {
    background: none;
    width: 1rem;
  }

  @include media(">desktop") {
    padding: 6.4rem 3.4rem;
  }
  @include media("<desktop") {
    padding-bottom: 15rem;
    background-position: center top, center bottom;
  }

  .inner_footer {
    display: flex;
    justify-content: space-between;
  }

  .section {
    .header {
      font-size: 2.2rem;
      font-weight: 700;
      line-height: 3.3rem;
      letter-spacing: -0.02em;
      margin-bottom: 3.6rem;
    }

    .bottom {
      margin-top: 5.2rem;
    }

    &.menu {
      width: 80rem;
      max-width: 65%;

      svg {
        width: 14rem;
        height: 3.3rem;
      }

      p {
        font-size: 1.7rem;
        color: rgba($color-light, 0.5);
        line-height: 2rem;
        max-width: 53rem;
      }

      .bottom {
        menu {
          display: flex;
          justify-content: space-between;
          font-size: 1.5rem;
          flex-wrap: wrap;


          @include media("<tablet") {
            flex-direction: column;
            display: block;
            width: calc(100vw - 3.2rem);
            padding-bottom: 4.8rem;
          }
          
          a {
            display: inline-block;
            padding: 0.1rem 0;
            margin: 1.2rem 1rem 0.1rem 0;

            @include media("<tablet") {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
    
    .alt_button {
      border: 0.2rem solid $color-light;
      font-size: 2.2rem;
      line-height: 2.5rem;
      max-width: 28.3rem;
      text-align: center;
      border-radius: $border-radius;
      padding: 0.5rem 2.4rem;
      display: inline-flex;
      align-items: center;

      @include media("<phoneXL") {
        font-size: 1.6rem;
        padding: 0.5rem 1.6rem;
      }
    }

    &.download {
      width: 44rem;
      max-width: 35%;
      align-items: center;

      @include media("<desktop") {
        text-align: right;
      }

      .main {
        display: flex;
        justify-content: center;

        a {
          order: 1;
        }
        
        @include media("<desktop") {
          flex-direction: column;
          align-items: flex-end;
          
          a + a {
            margin-left: 0;
          }
          .old_website {
            order: 2;
            margin-top: 1rem;
          }
        }
        
        a + a {
          margin-left: 1rem;

          html[dir="rtl"] & {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: flex-end;

        .social_button {
          svg {
            width: 2.2rem;
            height: 2.2rem;
          }

          &.facebook {
            width: 1rem;
          }
        }
        .social_button + .social_button {
          margin-left: 4rem;
        }
      }
    }
  }
}