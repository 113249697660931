@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .button {
      &:focus {
        outline: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transform: scale(1.05);
      }
    }
  }
}

.button {
  transition: 0.5s all;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem;
  padding: 1.6rem 1.6rem;
  background-color: $color-primary;
  color: $color-light;
  font-weight: 700;
  border-radius: 8px;

  &_text {
    background-color: transparent;
    color: $color-light;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    padding: 1.6rem 1.6rem;
    font-weight: 700;
  }

  &.compact {
    width: auto;
  }

  &.small {
    padding: 0.8rem;
  }

  &.accent {
    background-color: $color-accent;
    color: $color-black;
  }

  &_outline {
    border: 1px solid $color-light;
    background-color: transparent;
    color: $color-light;
  }

  svg {
    margin-left: 0.8rem;
  }
}
